import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import api from "../../utils/api";
import { AppThunk } from "../../store";
import { ISliceStatus } from "../../interfaces";
import snack from "../../utils/snack";
import { IBatchesQuery } from "./batchesQuery";

export type IBatchStatus = "Submitted" | "In progress" | "Completed";

interface IBatchFile {
  filePath: string;
  createdAt: number;
  createdBy: string;
}

interface IBatch {
  _id: string;
  batchName: string;
  batchType: string;
  batchStatus: string;
  statusMessage?: string;
  statusDate?: number;
  batchSize?: number;
  downloadedBy?: string[];
  batchFlags?: string;
  files?: IBatchFile[];
}
interface IState {
  batchesByName: {
    [batchName: string]: IBatch;
  };
  status: ISliceStatus;
}

const initialState: IState = {
  batchesByName: {},
  status: "idle",
};

const sevenDays = 604800000;
export const batchesSlice = createSlice({
  name: "batches",
  initialState,
  reducers: {
    statusChanged: (state, action: PayloadAction<ISliceStatus>) => {
      state.status = action.payload;
    },
    batchesFetched: (state, action: PayloadAction<IBatch[]>) => {
      state.status = "ready";
      const reorderedItems = action.payload.reverse();
      for (const batch of reorderedItems) {
        if (batch.batchType === "rss") {
          const batchTimestamp = parseInt(batch._id.substring(0, 8), 16) * 1000;
          const diff = batchTimestamp - Date.now();
          if (diff < 0) {
            batch.batchStatus = "Completed";
          } else {
            batch.batchStatus = "In progress";
            batch.statusMessage = `${((1 - diff / sevenDays) * 100).toFixed(
              2
            )}% completed`;
          }
        }
        state.batchesByName[batch.batchName] = batch;
      }
    },
    batchesRefreshed: (state, action: PayloadAction<IBatch[]>) => {
      const batchesByName: IState["batchesByName"] = {};
      const reorderedItems = action.payload.reverse();
      for (const batch of reorderedItems) {
        if (batch.batchType === "rss") {
          const batchTimestamp = parseInt(batch._id.substring(0, 8), 16) * 1000;
          const diff = batchTimestamp - Date.now();
          if (diff < 0) {
            batch.batchStatus = "Completed";
          } else {
            batch.batchStatus = "In progress";
            batch.statusMessage = `${((1 - diff / sevenDays) * 100).toFixed(
              2
            )}% completed`;
          }
        }
        batchesByName[batch.batchName] = batch;
      }
      state.batchesByName = batchesByName;
      state.status = "ready";
    },
  },
  extraReducers: {
    "session/logOut": () => initialState,
  },
});

export const { statusChanged, batchesFetched, batchesRefreshed } =
  batchesSlice.actions;

export const getBatches =
  (query: IBatchesQuery): AppThunk =>
  async (dispatch) => {
    dispatch(statusChanged("loading"));
    const res = await api.fetch({
      path: "/batches",
      method: "GET",
      query,
    });
    if (res.ok) {
      dispatch(batchesRefreshed(res.payload));
    }
  };

interface IDownloadBatchQuery {
  batchId: string;
  keywords?: string;
  clusters?: number[];
  excludedColumns?: string;
}

export const downloadBatchData =
  (query: IDownloadBatchQuery): AppThunk =>
  async (dispatch) => {
    snack.info("Preparing. You will receive an email when it's ready...");
    const res = await api.fetch({
      path: "/batchData",
      method: "GET",
      query,
    });
    if (res.ok) {
      snack.success(`Files are ready!`);
      dispatch(batchesFetched([res.payload.batch]));
    }
  };

export const downloadBatchScreenshots =
  (query: { batchId: string }): AppThunk =>
  async () => {
    snack.info("Preparing. It may take a few minutes...");
    const res = await api.fetch({
      path: "/batchScreenshots",
      method: "GET",
      query,
    });
    if (res.ok) {
      snack.success(`Done! Downloading...`);
      setTimeout(() => {
        res.payload.forEach((signedUrl: string) => {
          window.open(signedUrl);
        });
      }, 1500);
    }
  };

export const downloadImages =
  (query: { batchId: string }): AppThunk =>
  async () => {
    snack.info("Preparing. It may take a few minutes...");
    const res = await api.fetch({
      path: "/images",
      method: "GET",
      query,
    });
    if (res.ok) {
      snack.success(`Done! Downloading...`);
      setTimeout(() => {
        res.payload.forEach((signedUrl: string) => {
          window.open(signedUrl);
        });
      }, 1500);
    }
  };

export const postToClay =
  (batchId: string): AppThunk =>
  async () => {
    snack.info("Pushing to Clay...");
    if (!window.confirm("Are you sure you want to push this batch to Clay?")) {
      return;
    }
    const tableUrl = window.prompt(
      "[Optionally] Specify the destination table url. Leave blank to use the default one."
    );
    const res = await api.fetch({
      path: "/clay/" + batchId,
      method: "POST",
      body: { tableUrl },
    });
    if (res.ok) {
      snack.success(`Done!`);
    }
  };

export const downloadBatchReport =
  (query: { batchId: string }): AppThunk =>
  async () => {
    snack.info("Preparing. It may take a few minutes...");
    const res = await api.fetch({
      path: "/batchReport",
      method: "GET",
      query,
    });
    if (res.ok) {
      snack.success(`Done! Downloading...`);
      setTimeout(() => {
        res.payload.forEach((signedUrl: string) => {
          window.open(signedUrl);
        });
      }, 1500);
    }
  };

interface IPostBatch {
  batchName: string;
  batchType: string;
  batchData: string;
  batchFlags: string;
}
type IPostBatchCb = () => void;
export const postBatch =
  (body: IPostBatch, cb: IPostBatchCb): AppThunk =>
  async (dispatch) => {
    snack.info("🤞 Validating batch...");
    const res = await api.fetch({
      path: "/batch",
      method: "POST",
      body,
    });
    if (res.ok) {
      dispatch(batchesFetched([res.payload]));
      cb();
      snack.success("🚀 Batch submitted!");
    }
  };

interface IDownloadBatchFileQuery {
  batchId: string;
  filePath: string;
}

export const downloadBatchFile =
  (query: IDownloadBatchFileQuery): AppThunk =>
  async () => {
    snack.info("Preparing file download...");
    const res = await api.fetch({
      path: "/batchFile",
      method: "GET",
      query,
    });
    if (res.ok) {
      snack.success(`Done! Downloading ${query.filePath}...`);
      setTimeout(() => {
        window.open(res.payload.signedUrl);
      }, 500);
    }
  };

export default batchesSlice.reducer;
