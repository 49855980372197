import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "../../store";
import api from "../../utils/api";
import { ISliceStatus } from "../../interfaces";
import snack from "../../utils/snack";
import parseSchema from "./parseGbqMetadata";
import {
  GridAlignment,
  GridFilterModel,
  GridSortModel,
} from "@mui/x-data-grid-premium";
import { batch } from "react-redux";
import { defaultPageSize } from "./TableView";

// const metadata = {
//   "kind": "bigquery#table",
//   "etag": "fhvTg/JJTiRlPTego+eWHA==",
//   "id": "peg-sandbox:explorer.all_companies",
//   "selfLink": "https://bigquery.googleapis.com/bigquery/v2/projects/peg-sandbox/datasets/explorer/tables/all_companies",
//   "tableReference": {
//     "projectId": "peg-sandbox",
//     "datasetId": "explorer",
//     "tableId": "all_companies"
//   },
//   "schema": {
//     "fields": [
//       { "name": "linkedin", "type": "STRING" },
//       { "name": "website", "type": "STRING" },
//       { "name": "name", "type": "STRING" }
//     ]
//   },
//   "numBytes": "28156627648",
//   "numLongTermBytes": "0",
//   "numRows": "8596272",
//   "creationTime": "1689685206149",
//   "lastModifiedTime": "1689685206149",
//   "type": "TABLE",
//   "location": "US",
//   "numTimeTravelPhysicalBytes": "0",
//   "numTotalLogicalBytes": "28156627648",
//   "numActiveLogicalBytes": "28156627648",
//   "numLongTermLogicalBytes": "0",
//   "numTotalPhysicalBytes": "8085108937",
//   "numActivePhysicalBytes": "8085108937",
//   "numLongTermPhysicalBytes": "0"
// }

type IGbqDataType =
  | "INTEGER"
  | "FLOAT"
  | "NUMERIC"
  | "BIGNUMERIC"
  | "BOOLEAN"
  | "STRING"
  // | 'BYTES'
  | "DATE"
  | "DATETIME"
  | "TIME"
  | "TIMESTAMP";
// | 'STRUCT'
// | 'GEOGRAPHY'
// | 'JSON'

export interface ITableMetadata {
  // reflects the sample above
  kind: string;
  etag: string;
  id: string;
  selfLink: string;
  tableReference: {
    projectId: string;
    datasetId: string;
    tableId: string;
  };
  schema: { fields: { name: string; type: IGbqDataType }[] };
  numBytes: number;
  numLongTermBytes: number;
  numRows: number;
  creationTime: number;
  lastModifiedTime: number;
  type: string;
  location: string;
  numTimeTravelPhysicalBytes: number;
  numTotalLogicalBytes: number;
  numActiveLogicalBytes: number;
  numLongTermLogicalBytes: number;
  numTotalPhysicalBytes: number;
  numActivePhysicalBytes: number;
  numLongTermPhysicalBytes: number;
}

type IRow = {
  [key: string]: string | number | boolean | null | undefined;
};

type IRawRow = {
  [key: string]: any; // string | number | boolean | null | undefined | { value: string };
};

type IDataGridColumnType =
  | "string"
  | "number"
  | "date"
  | "dateTime"
  | "boolean";
// | "singleSelect"
// | "actions";
export interface IColumn {
  field: string;
  headerName: string;
  width: number;
  type: IDataGridColumnType;
  align?: GridAlignment;
}

interface IState {
  metadata?: ITableMetadata;
  rows: IRow[];
  columns: IColumn[];
  status: ISliceStatus;
}

const gbqToDataGridType: {
  [key in IGbqDataType]: IDataGridColumnType;
} = {
  INTEGER: "number",
  FLOAT: "number",
  NUMERIC: "number",
  BIGNUMERIC: "number",
  BOOLEAN: "boolean",
  STRING: "string",
  DATE: "date",
  DATETIME: "dateTime",
  TIME: "dateTime",
  TIMESTAMP: "dateTime",
};

const initialState: IState = {
  rows: [],
  columns: [],
  status: "idle",
};

const specialColumns: {
  [key: string]: { width: number; align?: GridAlignment };
} = {
  context_left: { width: 1450, align: "right" },
  context_right: { width: 1450 },
  text: { width: 200, align: "center" },
};

export const tableSlice = createSlice({
  name: "table",
  initialState,
  reducers: {
    fetched: (
      state,
      action: PayloadAction<{
        rows: IRawRow[];
        metadata: ITableMetadata;
      }>
    ) => {
      const unwrappableKeys: string[] = [];
      state.metadata = action.payload.metadata;
      state.columns = action.payload.metadata.schema.fields.map((field) => {
        if (["DATE", "DATETIME", "TIME", "TIMESTAMP"].includes(field.type)) {
          unwrappableKeys.push(field.name);
        }
        const defaultWidth = Math.min(200, field.name.length * 7 + 90);
        return {
          field: field.name,
          headerName: field.name,
          width: specialColumns[field.name]?.width || defaultWidth,
          type: gbqToDataGridType[field.type],
          ...(specialColumns[field.name]?.align
            ? { align: specialColumns[field.name]?.align }
            : {}),
        };
      });

      if (!action.payload.rows.length) {
        state.rows = [];
        state.status = "empty";
        return;
      }
      unwrappableKeys.forEach((key) => {
        action.payload.rows.forEach((row) => {
          if (row[key] && row[key].value) {
            row[key] = row[key].value;
          }
        });
      });

      state.rows = action.payload.rows;
      state.status = "ready";
    },
    statusChanged: (state, action: PayloadAction<ISliceStatus>) => {
      state.status = action.payload;
    },
  },
});

export const { fetched, statusChanged } = tableSlice.actions;

interface IGetTablePreviewQuery {
  offset: number;
  limit: number;
  excludedColumns?: string[];
}

export const getTablePreview =
  (tableId: string, query?: IGetTablePreviewQuery): AppThunk =>
  async (dispatch, getState) => {
    dispatch(statusChanged("loading"));
    let metadata = getState().table.metadata;

    if (!metadata || metadata.id !== tableId) {
      const res = await api.fetch({
        path: `/gbqTableMetadata/${tableId}`,
        method: "GET",
      });
      if (res.ok) {
        metadata = parseSchema(res.payload);
      } else if (res.serverIsOk) {
        snack.info("Redirecting...");
        setTimeout(() => {
          window.location.href = "/explorer";
        }, 2000);
      }
    }
    if (!metadata) {
      snack.error("Table not found");
      return dispatch(statusChanged("error"));
    }
    const rows: IRawRow[] = [];
    const limit = query?.limit || defaultPageSize;
    let maxRequests = 10;
    while (rows.length < limit && maxRequests > 0) {
      maxRequests--;
      const res = await api.fetch({
        path: `/gbqTablePreview/${tableId}`,
        // POST, because excludedColumns can be too long for GET
        method: "POST",
        body: {
          ...query,
          offset: (query?.offset || 0) + rows.length,
          limit: Math.min(limit, limit - rows.length),
        },
      });
      if (!res.ok || !res.payload.rows.length) {
        break;
      } else {
        rows.push(...res.payload.rows);
      }
    }
    dispatch(fetched({ rows, metadata }));
  };

interface IPostQuery {
  tableId: string;
  theme?: string;
  filterModel?: GridFilterModel;
  sortModel?: GridSortModel;
}
export const postQuery =
  (body: IPostQuery): AppThunk =>
  async (dispatch) => {
    if (!body.theme) {
      if (
        !window.confirm(
          "You didn't select a theme. Are you sure you want to continue?"
        )
      ) {
        return;
      }
    }
    dispatch(statusChanged("loading"));
    const res = await api.fetch({
      path: `/gbqQuery`,
      method: "POST",
      body: body,
    });
    if (res.ok) {
      batch(() => {
        dispatch(getTablePreview(res.payload.queryDestinationTableId));
      });
    } else {
      dispatch(statusChanged("error"));
    }
  };

export const downloadTable =
  (tableId: string, body: { excludedColumns: string[] }): AppThunk =>
  async () => {
    snack.info("Preparing. It may take a minute...");
    const res = await api.fetch({
      path: `/gbqTableDownload/${tableId}`,
      method: "POST",
      body,
    });
    if (res.ok) {
      snack.success(`Done! Downloading...`);
      setTimeout(() => {
        window.open(res.payload.signedUrl);
      }, 1500);
    }
  };

interface ISampleTable {
  tableId: string;
  theme?: string;
  percentage: number;
}
export const sampleTable =
  (body: ISampleTable): AppThunk =>
  async (dispatch) => {
    if (!body.theme) {
      if (
        !window.confirm(
          "You didn't select a theme. Are you sure you want to continue?"
        )
      ) {
        return;
      }
    }
    dispatch(statusChanged("loading"));
    const res = await api.fetch({
      path: `/gbqSample`,
      method: "POST",
      body: body,
    });
    if (res.ok) {
      batch(() => {
        dispatch(getTablePreview(res.payload.destinationTableId));
      });
    } else {
      dispatch(statusChanged("error"));
    }
  };

interface IImportTable {
  name: string;
  websites: string;
  theme?: string;
}
export const importTable =
  (body: IImportTable): AppThunk =>
  async (dispatch) => {
    snack.info("Importing table, it may take a minute...");
    const res = await api.fetch({
      path: `/gbqTableImport`,
      method: "POST",
      body,
    });
    if (res.ok) {
      snack.success("Done! Redirecting...");
      dispatch(getTablePreview(res.payload.destinationTableId));
    } else {
      console.log(res);
      snack.error("Failed to import table");
      dispatch(statusChanged("error"));
    }
  };

export default tableSlice.reducer;
